:root {
  --accent: #f5b32f;
  --accent-hover: #eba312;
  --light-accent-color: #ffce6b;
  --dark-accent-color: #ffa200;

  --green-light: #E1FFDF;
  --green: #11D600;
  --green-dark: #0da200;

  --blue-light: #DBF4FF;
  --blue: #00B2FF;
  --blue-dark: #2290fe;

  --orange-light: #FEF0E1;
  --orange: #FA971C;
  --orange-dark: #ce7a14;

  --red-light: #FEECEC;
  --red: #F96A6A;
  --red-dark: #d74040;

  --font-small: 16px;
  --font-medium: 24px;
  --font-large: 36px;
}

:root.light {
  --gray-light: #D9D9D9;
  --gray: #656565;
  --gray-dark: #454545;

  --dark-bg: #222222;
  --dark-bg-hover: #303030;
  --primary-gray: #f6f6f6;
  --secondary-gray: #e1e1e1;

  --bg-main: #FFFFFF;
  --bg-main-light: #F7F7F7;

  --text-light: #f1f1f1;
  --text-main: #1a1a1a;
}

:root.dark {
  --gray-light: #4d4d4d;
  --gray:  #aaaaaa;
  --gray-dark: #454545;

  --dark-bg: #222222;
  --dark-bg-hover: #646464;
  --primary-gray: #353535;
  --secondary-gray: #585858;

  --bg-main: #111111;
  --bg-main-light: #272727;

  --text-light:#f1f1f1;
  --text-main: #f1f1f1;
}

:root.font-size-small {
  --font-small: 14px;
  --font-medium: 20px;
  --font-large: 32px;
}
:root.font-size-medium {
  --font-small: 16px;
  --font-medium: 24px;
  --font-large: 36px;
}
:root.font-size-large {
  --font-small: 18px;
  --font-medium: 28px;
  --font-large: 40px;
}
:root.font-size-vLarge {
  --font-small: 20px;
  --font-medium: 30px;
  --font-large: 46px;
}

@font-face {
  font-family: "Gilroy";
  src: url(../assets/fonts/Gilroy-Light.woff);
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url(../assets/fonts/Gilroy-ExtraBold.woff);
  font-weight: 900;
}

* {
  color: var(--text-main);
  font-family: "Gilroy";
}

body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  background: var(--bg-main);
}

:root:not(.light) .black-icon {
  filter: invert(1);
}

.slide-in-front,
.slide-in-back,
.slide-out-front,
.slide-out-back {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transform-origin: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slide-in-front,
.slide-out-front {
  z-index: 15;
  background: var(--accent);
}

.slide-in-back {
  z-index: 14;
  background: var(--light-accent-color);
}

.slide-out-back {
  z-index: 14;
  background: var(--dark-accent-color);
}

.apexcharts-tooltip.apexcharts-theme-light,
.apexcharts-tooltip.apexcharts-theme-light div,
.apexcharts-tooltip.apexcharts-theme-light span,
.apexcharts-menu.apexcharts-menu-open div {
  color: black;
}

@media (max-width: 500px) {
  :root {
    --font-small: 14px;
  }
}